<template>
    <div class="home">
            <div class="grid-container" id="main">
        <div class="grid-x">
            <div class="medium-10 medium-offset-1 cell"><a href="/"><img class="img-responsive" id="logo"
                        itemprop="logo"
                        src="https://res.cloudinary.com/dfs5ueu1e/image/upload/c_scale,w_1024/v1499222227/neilsbahr/general/logo1.jpg"
                        alt="Neil's Bahr" /></a></div>
        </div>
        <div class="grid-x">
            <div class="medium-10 medium-offset-1 cell">
                <div class="panel" id="navigation">
                    <ul class="social-nav align-right">
                        <li> <a class="has-tip" title="Facebook" data-tooltip="data-tooltip" aria-haspopup="true"
                                data-disable-hover="false" data-position="bottom" tabindex="1"
                                href="https://www.facebook.com/pages/Neils-Bahr/618534184880635?ref=br_tf"
                                target="_blank"><i class="fi-social-facebook"></i></a></li>
                        <li><a class="has-tip" title="Twitter" data-tooltip="data-tooltip" aria-haspopup="true"
                                data-disable-hover="false" data-position="bottom" tabindex="1"
                                href="https://twitter.com/neilsbahr" target="_blank"><i
                                    class="fi-social-twitter"></i></a></li>
                        <li><a class="has-tip" title="Yelp" data-tooltip="data-tooltip" aria-haspopup="true"
                                data-disable-hover="false" data-position="bottom" tabindex="1"
                                href="http://www.yelp.com/biz/neils-bahr-houston" target="_blank"><i
                                    class="fi-social-yelp"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid-x">
            <div class="medium-10 medium-offset-1 cell">
                <div class="panel">
                    <div class="grid-x">
                        <div class="small-12 medium-8 medium-offset-2 cell"><img
                                src="https://res.cloudinary.com/dfs5ueu1e/image/upload/c_scale,w_640/v1508455510/neilsbahr/general/group_z.jpg"
                                alt="Neil of Neil's Bahr" /></div>
                        <div class="small-12 medium-8 medium-offset-2 cell" id="intro">
                            <p class="lead">This Conveniently located East Downtown bar is Houston’s new Premier
                                Nerd/Gamer Houstonian hangout and is within walking distance of the <a
                                    href="http://www.bbvacompassstadium.com\">Dynamo Stadium</a>, <a
                                    href="http://warehouselive.com\">Warehouse Live</a>, <a
                                    href="http://luckyspub.com\">Lucky’s Pub</a>, and <a
                                    href="http://houston.astros.mlb.com/hou/ballpark/\">Minute-Maid Park.</a></p>
                            <p> Weekly karaoke (Wednesdays), video game tournaments (Tuesdays) and movie nights
                                (Mondays), Pablo cooking on the patio, a comic book selection and free-play arcades are
                                just a few things that makes Neil’s a great place to get together with friends. Take a
                                look around and check out our <a
                                    href="https://www.facebook.com/pages/Neils-Bahr/618534184880635?ref=br_tf\">Facebook</a>
                                page for specials and event updates.</p>
                        </div>
                    </div>
                </div>
                <div class="panel" id="hours">
                    <p>Mon - Fri & Sun: 4pm - 2am | Sat: 2pm - 2am </p>
                </div>
                <div class="panel" id="map"><iframe class="scrollOff" width="100%" height="100%" frameborder="0"
                        style="border:0;"
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJZ_VDuiG_QIYRzXOmpYovGZc&amp;key=AIzaSyB0GdiOrRTCLejc76FOw94gqaHNC5Y8DAQ"
                        allowfullscreen="allowfullscreen"></iframe></div>
                <div class="panel" id="focus">
                    <div class="grid-x grid-padding-x">
                        <div class="small-12 medium-6 cell listContainer">
                            <h3>Features</h3>
                            <ul class="featuresList">
                                <li>Free WiFi</li>
                                <li><hr></li>
                                <li>Massive Comic Book Collection</li>
                                <li><hr></li>
                                <li>Three Free-Play Multicades</li>
                                <li><hr></li>
                                <li>Free Nintendo/Sega Console Game Play</li>
                                <li><hr></li>
                                <li>Board/Card Games </li>
                                <li><hr></li>
                            </ul>
                        </div>
                        <div class="small-12 medium-6 cell fbPlugin"><iframe
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNeils-Bahr-618534184880635%2F%3Fref%3Dbr_tf&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=true&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
                                width="375" height="450" style="border:none;overflow:hidden;" scrolling="no"
                                frameborder="0" allowTransparency="true"></iframe></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-x">
            <div class="medium-10 medium-offset-1 cell">
                <footer id="footer">
                    <div class="grid-x"></div>
                    <div class="grid-x" id="creditBar">
                        <div class="medium-12 cell">
                            <p class="text-muted">© Copyright: Neil's Bahr 2017 | 2006 Walker Street Houston, TX 77003 |
                                <a href="tel:7132370644">Phone: 713.237.0644</a> | <a
                                    href="mailto:neilsbahr@gmail.com">Email Us!</a></p>
                        </div>
                        <p class="text-muted" id="myCredit">Designed and Built with &hearts; by <a
                                href="https://brentbonilla.com" target="_blank">Brent Bonilla</a> </p>
                    </div>
                </footer>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'HomePage',
    // Your component options go here
}
</script>

<style scoped>
/* Your component styles go here */
</style>